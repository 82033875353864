import React from "react";
import '../../css/elements.css';
import { toggleSelectedOption,toggleSearch } from "../../features/leadFilterSlice";
import { useSelector, useDispatch } from 'react-redux';


function RemoveableOption({}) {
    const dispatch=useDispatch();
    const selectedOptions = useSelector(state => state.filterSearch.selectedOptions);
    const removeOption=(option)=>{
        dispatch(toggleSearch({search:option.option,option:option.search}))
        dispatch(toggleSelectedOption(option))
    }
    return (
        selectedOptions.length > 0 && selectedOptions.map((option, index) => (
            console.log(option),
            <div className="removeableOption">
                <span className="removeableRemove" onClick={()=>removeOption(option)}>X</span>
                <span className="removeableTitle">{option.name ? option.name : option.search}</span>
            </div>
        ))
    );
}

export default RemoveableOption;

 