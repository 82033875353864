import { fetchWithDefaults } from '../config/fetchDefault';

const runVM = async (profile, setResults, leadId) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                leadId:leadId,
                profile:profile,
            }) 
        };

        const response = await fetchWithDefaults('/virtualMentor/search', requestOptions);
        
        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        const combineDataAndQuotes = (data, quotes) => {
            return data.map(product => {
              const matchingQuotes = quotes.filter(quote => quote.ProductUID === product.uid);
              return {
                ...product,
                quotes: matchingQuotes,
              };
            });
        };
        if(jsonData){
            const results=combineDataAndQuotes(jsonData.data,jsonData.quotes)
            setResults(results);
        }
    } 
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
};
export {runVM};