import React from 'react';
import LeadTopBarButton from './leadTopBarButton';
import { useSelector } from 'react-redux';

function LeadCardTopBar({setQualifier, qualifier, leadData, newEventPackage, calendarUsing, defaultCalendar, setEvent, newAppt, setNewAppt, update}) {
    const scopes=useSelector((state) => state.scopes.scopes);
    const buttonInfo = ['Attach File','Schedule Call','Appointment','Qualifier','Lead PDF'];
    return (
        <div className="leadTopBarWrapper">
            <div className="leadTopBarInnerWrapper">
                {buttonInfo.map((field, index) => (
                    (field === 'Lead PDF' && leadData.pdf !== null) || field !== 'Lead PDF' ? (
                        scopes.length < 1  && field==='Appointment' ? (
                            null
                        ) : (
                            <LeadTopBarButton update={update} key={`topBarButton${index}`} setQualifier={setQualifier} qualifier={qualifier} calendarUsing={calendarUsing} newAppt={newAppt} setNewAppt={setNewAppt} defaultCalendar={defaultCalendar} newEventPackage={newEventPackage} setEvent={setEvent} leadData={leadData} title={field} />
                        )
                    ) : null
                ))}
            </div>
        </div>
    );
}

export default LeadCardTopBar;