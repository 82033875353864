import { createSlice } from '@reduxjs/toolkit'

export const callSlice = createSlice({
    name: 'call',
    initialState: {
        call: [],
        ringState:'disconnected'
    },
    reducers: {
        setCall(state, action) {
            state.call = action.payload;
        },
        setRingState(state, action) {
            state.ringState = action.payload;
        },
    },
})
export const { setCall, setRingState } = callSlice.actions;

export default callSlice.reducer

