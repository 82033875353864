import React, { useState, useRef } from "react";
import '../../css/integrations.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import IntegrationBlock from "./integrationBlock";
import { useSelector } from 'react-redux';



function Integrations({ data,title }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const innerWrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const agentId=agentInfo.agentId;

    let google;
    let calendar=false;
    let gmail=false;
    let zoom=false;
    let quility=false;

    if(data.length > 0){
        google=data[0].googleScopes;

        let zoomed=data[0].zoomAuth;
        if(google && google.includes("https://www.googleapis.com/auth/calendar.events")){
            calendar=true;
        }
        if(google && google.includes("https://www.googleapis.com/auth/gmail.send")){
            gmail=true;
        }
        if(google && google.includes("https://www.quility.com/auth/gmail.send")){
            quility=true;
        }
        if(zoomed && zoomed !== null){
            zoom=true;
        }
    }
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const handleOptionClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="outerWrapper" style={{marginTop:'50px', textAlign:'center'}}>
            <span className={`quilityParentBlockWrapper`} style={{ height: isOpen && getHeight() !==0 ? getHeight()+90 : 'fit-content', width:'70%'}} >
                <div className="quilityParentBlockHeader" onClick={()=>handleOptionClick()}>
                    <div className="quilityParentBlockTitle">{title}</div>
                    <span className="quilityBlockIcon"></span>
                </div>
                <div className="quilityParentBlockContent"  ref={innerWrapperRef}>
                    <div className="integrationHoler">
                        <IntegrationBlock removeLink={`/google/revoke`} title={'Google Calendar'} logo="https://app.ninjadialer.com/logo/calendar.png" status={calendar} button="https://app.ninjadialer.com/logo/googleButton.png" link={`https://${window.location.hostname}:3333/google/auth?agent=${agentId}`}/>
                        <IntegrationBlock removeLink={`/google/revoke`} title={'Gmail'} logo="https://app.ninjadialer.com/logo/gmail.png" status={gmail} button="https://app.ninjadialer.com/logo/googleButton.png" link={`https://${window.location.hostname}:3333/google/auth/gmail?agent=${agentId}`}/>
                        <IntegrationBlock title={'Zoom'} logo="https://app.ninjadialer.com/logo/zoom.png" status={zoom} button="toggle" link={`https://zoom.us/oauth/authorize?response_type=code&client_id=RFjIPVriRaufQ7qgcVAh6w&redirect_uri=https://app.ninjadialer.com/api/zoom/zoom.php?v2agent=${agentId}`}/>
                    </div>
                </div>
            </span>
        </div>
    );
}

export default Integrations;

// 
