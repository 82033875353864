import React, {useState, useEffect} from "react";
import AudioElement from './audioElement';
import '../../css/recording.css';
import AddRecording from "./addVoiceRecording";
import Icon from "../../assets/icons/icon";


function Voicemail({data}) {
    const [voicemails, setVoicemails] = useState([]);
    const [addRecording, setAddRecording] = useState(false);
    const handAddRecording= () => {
        setAddRecording(!addRecording);
    }
    useEffect(() => {
        setVoicemails(data[0]);
    }, []);
    return (
        <div className="outerWrapper">
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">

                    <span className="fontello"><Icon name="q-voicemail" size={22} className="topBarIcon" adjust="fontello" /></span>
                </span>
                Voicemail Box
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >
           
                <div className="frontPageQuilityParentBlockWrapper" style={{width:'50%',position: 'relative'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Voicemail Box Recordings</div>

                    </div>
                   {addRecording ? (
                        <div className="quilityParentBlockContent">
                            <div className="quilityCloseWindow" onClick={()=>setAddRecording(false)} style={{top:'0px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                            <AddRecording setVoicemails={setVoicemails} voicemails={voicemails} setAddRecording={setAddRecording} type='voicemail'/>
                            <div className="blockFooter">
                                <div
                                    className="buttonWrapper"
                                    style={{ marginTop: " 5px", marginLeft: "10px" }}
                                >
                                    <div className="dataButtonBlock">Recordings: {voicemails && voicemails.length > 0 ? voicemails.length:0}</div>
                                    <div className="actionButtonBlock" style={{width: '150px'}}>
                                        <div className="accentButton solidQuilityActiveButton" onClick={()=>handAddRecording()}>
                                        +  New Recording
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   ) : (
                        <div className="quilityParentBlockContent">
                            {voicemails && voicemails.length > 0 ? voicemails.map((item, index) => (
                                <AudioElement type="voicemails" key={`voicemails${index}`} index={index} audio={item}/>
                            )) : (
                                <div className="noResults">You have no voicemail setup</div>
                            )}
                            <div className="blockFooter">
                                <div
                                    className="buttonWrapper"
                                    style={{ marginTop: " 5px", marginLeft: "10px" }}
                                >
                                    <div className="dataButtonBlock">Recordings: {voicemails && voicemails.length > 0 ? voicemails.length:0}</div>
                                    <div className="actionButtonBlock" style={{width: '150px'}}>
                                        <div className="accentButton solidQuilityActiveButton" onClick={()=>handAddRecording()}>
                                        +  New Recording
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   )}
                </div>
            </div>
        </div>
    );
}

export default Voicemail;
//closeMainBlock focusMainBlock

