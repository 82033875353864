import React, {useState, useEffect} from "react";
import '../../css/tutorial.css';
import TutorialBlock from "./tutorialBlock";
import Numbers from "../number/number";
import TrustHub from "../trust/trustHubMain";
import Voicemail from "../voicemail/voicemail";
import VoiceDrops from "../voicemail/voiceDrop";
import CallForward from "../callForwarding/callForward";
import { useSelector } from 'react-redux';
import Integrations from "../integrations/Integrations";
import {getComponent} from '../../utilities/appSetup';
import Icon from "../../assets/icons/icon";
import CallRecording from "../recording/recording";
import { finishTutorial } from "../../utilities/appSetup";


function Tutorial({ }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const [showComponent, setShowComponent] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showPath, setShowpath] = useState(false);
    const [data, setData]= useState(false);
    const [show, setShow]= useState(true);


    const blocks=[
        {title:"Get Number", required:true, path:'Number', component:<Numbers data={data} from='tutorial'/>, tip:'You will want to get a number to make calls and send texts. These can be changed out after having them for 10 days. For some people, having a local presence is important and for others it isn\'t, but you can get a number in almost any area code!', video:'<iframe width="560" height="315" src="https://www.youtube.com/embed/_K2XmLUvCn8?si=Gb7oJzHmK1252aLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'},
        {title:"Register Trust", required:true, path:'Trust_Hub', component:<TrustHub data={data} from='tutorial'/>, tip:'This is the most important thing you can get done. This will allow you to send text messages, but it can also improve your call reputation which will increase your answer rates and call success.', video:''},
        {title:"Call Recording", required:true, path:'Call_Recording', component:<CallRecording data={data}/>, tip:'There are different laws for different states, so understanding how and when you can legally and compliantly record a conversation is important. We will help you along the way, don\'t worry.', video:''},
        {title:"Voice Mail", required:false, path:'Voicemail', component:<Voicemail data={data} from='tutorial'/>, tip:'Record a voicemail so people know who they are calling when they call you back. This works just how answering machines and voicemail have worked for decades - still a staple in building confidence in your clients.', video:''},
        {title:"Voice Drop", required:false, path:'VoiceDrop', component:<VoiceDrops data={data} from='tutorial' />, tip:'Voice mail drops are where you can record a clip of yourself talking, and leave it on your clients voicemail. This is going to keep your from repeating yourself tons of times.', video:''},
        {title:"Call Forwarding", required:true, path:'Call_Forwarding', component:<CallForward data={data} from='tutorial'/>, tip:'We know you don\'t always have your work things with you. While our phone app will make this not as important, it is still useful if you need to send an incoming call to a different phone number', video:''},
        {title:"Integrations", required:false, path:'Integrations', component:<Integrations data={data} from='tutorial' />, tip:'This is where you can you link things like Google Calendar, Zoom, and Gmail', video:''}
    ]
    const components=(datas,searchPath)=>{
        const newData=datas;
        console.log(newData);
        const blocks=[
            {title:"Get Number", required:true, path:'Number', component:<Numbers data={datas} from='tutorial'/>, tip:'You will want to get a number to make calls and send texts. These can be changed out after having them for 10 days. For some people, having a local presence is important and for others it isn\'t, but you can get a number in almost any area code!', video:'<iframe width="560" height="315" src="https://www.youtube.com/embed/_K2XmLUvCn8?si=Gb7oJzHmK1252aLg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'},
            {title:"Register Trust", required:true, path:'Trust_Hub', component:<TrustHub data={datas} from='tutorial'/>, tip:'This is the most important thing you can get done. This will allow you to send text messages, but it can also improve your call reputation which will increase your answer rates and call success.', video:''},
            {title:"Call Recording", required:true, path:'Call_Recording', component:<CallRecording data={newData}/>, tip:'There are different laws for different states, so understanding how and when you can legally and compliantly record a conversation is important. We will help you along the way, don\'t worry.', video:''},
            {title:"Voice Mail", required:false, path:'Voicemail', component:<Voicemail data={datas} from='tutorial'/>, tip:'Record a voicemail so people know who they are calling when they call you back. This works just how answering machines and voicemail have worked for decades - still a staple in building confidence in your clients.', video:''},
            {title:"Voice Drop", required:false, path:'VoiceDrop', component:<VoiceDrops data={datas} from='tutorial' />, tip:'Voice mail drops are where you can record a clip of yourself talking, and leave it on your clients voicemail. This is going to keep your from repeating yourself tons of times.', video:''},
            {title:"Call Forwarding", required:true, path:'Call_Forwarding', component:<CallForward data={datas} from='tutorial'/>, tip:'We know you don\'t always have your work things with you. While our phone app will make this not as important, it is still useful if you need to send an incoming call to a different phone number', video:''},
            {title:"Integrations", required:false, path:'Integrations', component:<Integrations data={datas} from='tutorial' />, tip:'This is where you can you link things like Google Calendar, Zoom, and Gmail', video:''}
        ]
        const matchedBlock = blocks.find(block => block.path === searchPath);
        console.log(data);
        setShowComponent(matchedBlock.component);
    }
    const toggleShowVideo= (video)=>{
        setShowVideo(video);
    }
    const toggleShowComponent= async (path)=>{
        setShowpath(path);
        await getComponent(agentInfo,path,setData);
    }
    const toggleGoBack=()=>{
        setShowVideo(false);
        setShowComponent(false);
        setData(false);
    }
    useEffect(() => {
		if(data){
            components(data,showPath);
        }
	}, [data]);
    const close= async ()=>{
        setShow(false);
    }
    const handleFinish=()=>{
        finishTutorial(agentInfo.agentId)
        close();
    }
    return (
        show && (
        <div className="tutorialWrapper">
            <div className="title">Here are a few things to set up your system!</div>
            <div className="quilityCloseWindow" onClick={()=>close()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-10px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            <div className="tutorialContainer">
                {showVideo ? (
                    <>
                        <div className="goBack" onClick={()=> toggleGoBack()}>
                            Back
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: showVideo }} style={{textAlign: 'center'}}/>
                    </>
                ) : showComponent ? (
                    <>
                        <div className="goBack" onClick={()=> toggleGoBack()}>
                            Back
                        </div>
                        <div className="componentWrapper">
                            {showComponent}
                        </div>
                    </>
                ) : (
                    <>
                        {blocks.map((block, index) => (
                            <TutorialBlock toggleShowVideo={toggleShowVideo} title={block.title} required={block.required} path={block.path} component={block.component} toggleShowComponent={toggleShowComponent} tip={block.tip} video={block.video}/>
                        ))}
                    </>
                )}
                <div className="standardButton" style={{marginTop:'50px'}} onClick={()=>handleFinish()}>Finished</div>
            </div>
        </div>
        )
    );
}

export default Tutorial;

