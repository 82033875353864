import React, { useState, useEffect, useRef } from 'react';
import ContactField from './field';
import Icon from '../../../../assets/icons/icon';

function HomeInfo({leadData, handleLeadData, formatField, agentId, formatNumber}) {
    const [isOpen, setIsOpen] = useState(false);
    const homeInfo = ['mortgage','lender','home_value','term_length','loan_type','payment', 'address','city','state','zip','county'];
    const healthInfo = ['borrower_sex', 'tobacco', 'borrower_age', 'borrower_dob', 'height', 'weight', 'co_borrower_age','co_borrower_dob','co_borrower_height','co_borrower_weight', 'co_borrower_tobacco', 'co_borrower_sex'];
    const [innerWrapperHeight, setInnerWrapperHeight] = useState(0);

    const innerWrapperRef = useRef(null);

    const openUp = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (innerWrapperRef.current) {
            setInnerWrapperHeight(innerWrapperRef.current.clientHeight);
        }
    }, [isOpen]);
    return (
        
        <div className={`quilityNestedBlockWrapper ${isOpen ? '' : ''}`} >
            <div className="quilityNestedBlockHeader" onClick={()=>openUp()}>
                <div className="quilityParentBlockTitle">Additional Information </div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent" style={{ height: isOpen ? innerWrapperHeight + 25 : '0px' }}>
                <div className="innerWrapper" ref={innerWrapperRef}>
                    {healthInfo.map((field, index) => (
                        <div className="leadCardBlock doubleLine" key={`healthInfo${index}`}>
                            <ContactField handleLeadData={handleLeadData} field={field} agentId={agentId} leadData={leadData} formatField={formatField} index={index}/>
                        </div>
                    ))}
                    <div className="divider"></div>
                    {homeInfo.map((field, index) => (
                        <div className="leadCardBlock doubleLine" key={`healthInfo${index}`}>
                            <ContactField handleLeadData={handleLeadData} field={field} agentId={agentId} leadData={leadData} formatField={formatField} index={index}/>
                        </div>
                    ))}
                     
                </div>
            </div>
        </div>
    );
    
}

export default HomeInfo;

/*{keysWithNonNullValue.map((field, index) => (
                <div className="leadCardBlock doubleLine">
                    <div className="leadInfoLine">
                            
                    </div>
                </div>
            ))}*/