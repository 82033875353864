import React, { useState, useEffect, useRef } from 'react';
import parse, { domToReact } from 'html-react-parser';
import '../../../../css/qualifier.css';
import Icon from '../../../../assets/icons/icon';


import ReccomendationBlock from '../../../reccomendation/reccomendationBlock';

function Recommendation({selectedProducts, setSelectedProducts, handleProfileUpdate,setFaceAmount,faceAmount,term,setTerm,results,lead, profile, possibleConditions}) {
    const [extraInfo, setExtraInfo] = useState(false);
    //const [extraInfo, setExtraInfo] = useState(false);
    const [selectedTab, setSelectedTab] = useState('term');
    const [recWithQuote, setRecWithQuote] = useState(false);
    const options= [{"name": "Fully Underwritten","id": 20},{"name": "Simplified Issue","id": 19},{"name": "Term","id": 7},{"name": "Universal Life","id": 8},{"name": "Whole Life","id": 9},{"name": "Debt Free Life","id": 18},{"name": "Final Expense","id": 6},{"name": "Mortgage Protection","id": 5}];
    function findNamesByIds(ids) {
        return ids.map(id => {
            const item = options.find(item => item.id === id);
            return item ? <div key={id} className="recBubble">{item.name}</div> : null;
        }).filter(name => name !== null);
    }

    const handleSelectedTab=(type)=>{
        let typeId;
        if(type==='term'){
            typeId='7';
        }
        else{
            typeId='6'
        }
        handleProfileUpdate('productOption',typeId)
        setSelectedTab(type)
    }



    const handleSelectedProducts=(faceAmount, product)=>{
        setSelectedProducts((prevItems) => {
            const exists = prevItems.some(item => item.product.id === product.id);

            if (exists) {
              // Remove the item if it exists
              return prevItems.filter(item => item.product.id !== product.id);
            } else {
              // Add the item if it doesn't exist
              return [...prevItems, { face: faceAmount, product }];
            }
        });
    }

    const closePopup=()=>{
        setExtraInfo(false);
    }
    const handleTermChange=(event)=>{
        setTerm(event.target.value)
        handleProfileUpdate('term',event.target.value);
    }
    const handleFaceChange=(value)=>{
        setFaceAmount(value)
        handleProfileUpdate('faceAmount',value);
    }

    const transform = (node) => {
        if (node.type === 'tag' && node.name === 'strong') {
          return <div className="recDescriptionTitle">{domToReact(node.children, { transform })}</div>;
        }

        if (node.type === 'tag' && node.name === 'br') {
          return <br />;
        }

        return node;
    };
    useEffect(() => {

        setRecWithQuote()
    }, []);
    return (
        <div className='recWrapper'>
            <div className="productTabs">
                <div className={`productTab ${selectedTab==='term' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('term')}>Term Products</div>
                <div className={`productTab ${selectedTab==='whole' ? 'selectedTab':''}`} onClick={()=>handleSelectedTab('whole')}>Whole Life</div>
            </div>
            <div className="recOptions">

                <div className="recInputHolder">
                    <div className="recInputTitle">
                        Face Amount
                    </div>
                    <input type="text" className="recInput" onChange={(e)=>handleFaceChange(e.target.value)}value={faceAmount}/>
                </div>
                {selectedTab==='term' && (
                    <div className="recInputHolder">
                        <div className="recInputTitle">
                            Term Length
                        </div>
                        <select className="recInputSelect" onChange={handleTermChange} value={term}>
                            <option value="10">10 Year</option>
                            <option value="15">15 Year</option>
                            <option value="20">20 Year</option>
                            <option value="25">25 Year</option>
                            <option value="30">30 Year</option>
                            <option value="35">35 Year</option>
                            <option value="40">40 Year</option>
                        </select>
                        {/*<input type="text" className="recInput"/>*/}
                    </div>
                )}
            </div>
            <div className="recResults">
                {results && results.map((result, index) => (
                    <ReccomendationBlock faceAmount={faceAmount} possibleConditions={possibleConditions} profile={profile} lead={lead} reccomendation={result} handleSelectedProducts={handleSelectedProducts} setExtraInfo={setExtraInfo}/>
                ))}
            </div>
            {extraInfo && (
                <div className="extraPopup">
                    <div className="extraPopupContent">
                        <div className="extraPopupTitle">{extraInfo.name}</div>
                        <div className="quilityCloseWindow" style={{top:'5px'}} onClick={()=>closePopup()}><span className="fontello" style={{lineHeight: '12px',color:'grey'}}><Icon name={'x-close-q'} size={16} height="22" adjust="fontello" /></span></div>
                        <div className="optionBubbles">
                            {findNamesByIds(extraInfo.product_options)}
                        </div>
                        <div className="recDescription">{parse(extraInfo.description, { transform })}</div>
                        <div className="bottomLinks">
                            <div className="recBottomLink">
                                <a className="noDeco" href={extraInfo.guide_file} target='_blank'>Product Guide <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                            </div>
                            <div className="recBottomLink">
                                <a className="noDeco" href={extraInfo.url} target='_blank'>Company Website <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

}

export default Recommendation;

