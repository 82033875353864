import React, {useState, useEffect} from 'react';
import '../../css/callLog.css';
import { getVoicemails, voicemailHeard } from '../../utilities/phoneRelatedFunctions';
import { formatPhoneNumber, formatTimeStampToLocal } from '../../utilities/dashFunction';
import {setOpenVoicemails} from '../../features/topBarSlice';
import { setVoicemails, setUnheard } from '../../features/voicemailSlice';
import { useSelector, useDispatch } from 'react-redux';
import image from '../../assets/icons/loading-loader.gif';
import Icon from '../../assets/icons/icon';
import AudioPlayer from 'react-audio-player';


function Voicemails({openLead}) {
    const dispatch = useDispatch();
    const agentInfo=useSelector((state) => state.agent.userData);
    const voicemailState=useSelector((state) => state.topBar.voicemails);
    const voicemails=useSelector((state) => state.voicemails.voicemails);
    const [callsLoading, setVoicemailLoading] = useState(false);
    useEffect( () => {
        if(voicemailState){
            if(voicemails.length < 1){
                getVoicemails(agentInfo.agentId, setVoicemailLoading, setVoicemails, dispatch);
            }
        }
    },[voicemailState])
    function updateHeard(voicemailsArr, id) {
        return voicemailsArr.map(item => {
            if (item.id === id) {
                return { ...item, message_heard: 'yes' }; // Update the matching item
            }
            return item; // Return the item unchanged if the id does not match
        });
    }
    const hearLead=(heard, id)=>{
        if(heard==='no'){
            const newVoicemails=updateHeard(voicemails, id)
            voicemailHeard(id);
            dispatch(setUnheard(-1));
            dispatch(setVoicemails(newVoicemails));
        }
    }
    const handleClose=()=>{
        dispatch(setOpenVoicemails(false));
    }
    return (
        <>
            <div className={`leaderBoardWrapper ${voicemailState ? 'openedLeaderBoard': ''}`}>
                {callsLoading && (
                    <div className="smallLoadingWrapper">
                        <div className="innerSmallLoadingWrapper">
                            <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                        </div>
                    </div>
                )} 
                <div className="quilityCloseWindow" onClick={()=>handleClose()} style={{zIndex: '2'}}><span className="fontello"><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div> 
                <div className="leaderBoardHeader">
                    <div className="leaderBoardTitle">Voicemails</div>
                    
                </div>
                <div className="convoEntireWrapper">
                    <div className={`conversationLeft`}>
                        <div className="convoListInnerWrapper">
                            <div className="voicemailsBody">
                                {voicemails && voicemails.map((voicemailItem, index) => (
                                    <div className={`callItemWrapper ${voicemailItem.message_heard==='no' ? 'unheard':''}`} onClick={()=>[hearLead(voicemailItem.message_heard, voicemailItem.id),openLead(voicemailItem.lead_id)]}>
                                        <div class="callLogBlock">
                                            {voicemailItem.borrower_first ? (
                                                <span class="quilityDataLineTitle">{voicemailItem.borrower_first} {voicemailItem.borrower_last}</span>
                                            ) : (
                                                <span class="quilityDataLineTitle">{voicemailItem.message_from}</span>
                                            )}
                                            <div className="subLineWrapper">
                                                <span className={`directionIndication`}><span className="fontello"><Icon name={'q-voicemail'} size={18} className="" adjust="fontello" /></span></span>
                                                <span class="callWithItem">{formatPhoneNumber(voicemailItem.message_from)}</span> - 
                                                <span class="callWithItem">{formatTimeStampToLocal(voicemailItem.formatted_timestamp)}</span>
                                            </div>
                                            <span class="quilityDataLineValue">
                                                <AudioPlayer
                                                    src={voicemailItem.url}
                                                    autoPlay={false}
                                                    controls
                                                    className=""
                                                    onPlay={()=>hearLead(voicemailItem.message_heard, voicemailItem.id)}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Voicemails;
