import React, { useState } from 'react';
import Icon from '../../../assets/icons/icon';


function Medication({handleOpenSearch, usedMedications, removeMed, medicalForm,removeFormMedication}) {
    const [medInfo, setMedInfo] = useState(false);
    const searchMeds= async (medication) => {
        if(medication !=''){
            try {
                const response = await fetch(
                    `https://api.fda.gov/drug/label.json?api_key=I3cia1do3ZA2eUuPHmcBZKpRfbPsgxU4UDdQmhPz&search=openfda.brand_name%3A%22${encodeURIComponent(
                    medication
                    )}%22`,
                    {
                    method: 'GET',
                    headers: {
                        Authorization: 'Basic STNjaWExZG8zWkEyZVV1UEhtY0JaS3BSZmJQc2d4VTRVRGRRbWhQejo=',
                    },
                    }
                );
                const data = await response.json();
                if (!response.ok) {

                    throw new Error('Network response was not ok');
                }
                setMedInfo(data.results[0].indications_and_usage[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
    return (
        <div style={{width: "calc(50% - 40px)",textAlign: 'center', padding:'0px 10px'}}>
            <div className="addTo" onClick={()=>handleOpenSearch()}>Medication </div>
            <div className="conditionHolder">
                {medicalForm && medicalForm.length > 0 && medicalForm.map((med, index) => {
                    if (med.medicationid) {
                        return (
                            <div className="conditionBubble" key={index} style={{margin:'5px 10px'}}>
                                <span className="medInfo" onClick={()=>searchMeds(med.medication)}>i</span>
                                {med.medication}
                                <span className="smallRemove" onClick={() => removeFormMedication(med.medicationid,index)}>x</span>
                            </div>
                        );
                    } else {
                        return null; 
                    }
                })}
                {usedMedications.map((med, index) => (
                    <div className="conditionBubble" key={`medicationBubble${index}`}>{med}<span class="smallRemove" onClick={()=>removeMed(index)}>x</span></div>
                ))}
            </div>
            {medInfo && (
                <div className="medicationInfoWrapper">
                    <div className="medicationInfoContent">
                        <div class="quilityCloseWindow" style={{top:'5px'}} onClick={()=>setMedInfo(false)}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                        <span className="medInfoInner">{medInfo}</span>
                    </div>
                </div>
            )}
        </div>
    );

}

export default Medication;
