

import React from 'react';


function DialAmount({setDialAmount}) {
    
    
    return (
        <div className="warningWrapper">
            <div className="warningBody" style={{height:'35%'}}>
                <div className="warningHeader">
                    Dials Per Lead
                </div>
                <div className="warningContent">
                    How many dials per lead do you want to make?
                </div>
                <div className="warningButtonHolder" style={{margin: '0px 0px'}}>
                    <div className="warningButtonWrapper">
                        <div className="standardButton" key={'dial1'} onClick={()=>setDialAmount(1)}>1 Dial</div>
                        <div className="standardButton" key={'dial2'} onClick={()=>setDialAmount(2)}>2 Dials</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DialAmount;