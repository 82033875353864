import React, { useState, useRef, useEffect } from 'react';

import MedicationInfo from '../medicationSearch';
import QualifierSection from './section';
import Medication from './medication';
import Condition from './condition';
import ConditionInfo from '../conditionSearch';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { runVM } from '../../../utilities/virtualMentor';
import { useSelector } from 'react-redux';
import Icon from '../../../assets/icons/icon';



function QualifierBlock({title, lead, leadMainData, setLead, setResults, profile, setProfile, possibleConditions, setPossibleConditions}) {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [conditions, setConditions] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState(false);
    
    const [usedMedications, setUsedMedications] = useState([]);
    const [promptQuestion, setPromptQuestion] = useState(false);
    const [medicalForm, setMedicalForm] = useState(false);
    const [currentPendingCondition, setCurrentPendingCondition] = useState(false);
    const questions = [
        {question:"Years Since Diagnosis", column:'diagnosed'},
        {question:"Years Since Treatment",column:'last_treated'}
    ]
    const [inputValues, setInputValues] = useState(Array(questions.length).fill(''));
    const [isOpen, setIsOpen] = useState(true);
    const [isOpen2, setIsOpen2] = useState(true);
    const [isOpen3, setIsOpen3] = useState(true);
    const innerWrapperRef = useRef(null);
    const innerWrapperRef2 = useRef(null);
    const innerWrapperRef3 = useRef(null);
    const handleOptionClick = () => {
        setIsOpen(!isOpen);
    };
    const handleInputChange = (index, value, column) => {
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            newValues[index] = { value:value, column:column }; // Store value and column as an object
            return newValues;
        });
    };
    const handleOptionClick2 = () => {
        setIsOpen2(!isOpen2);
    };
    const handleOptionClick3 = () => {
        setIsOpen3(!isOpen3);
    };
    const saveConditionQuestion = async (answer)=>{
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    condition:currentPendingCondition.search_name,
                    questions:inputValues,
                    answer:answer,
                    form:lead[0].medicalForm,
                    agent:agentId,
                    lead:lead[0].lead_id
                }) 
            };
          
            const response = await fetchWithDefaults('/medicalForm/medicalFormChange', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLead('medicalForm',jsonData.form)
            
            fetchMedForm();
            setConditions([]);
            setCurrentPendingCondition(false);
            setInputValues(Array(questions.length).fill(''));
            setPromptQuestion(false);
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeFormCondition= async (condition,indexToRemove)=>{
        setMedicalForm(prevMedicalForm => prevMedicalForm.filter((_, index) => index !== indexToRemove));
        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    condition:condition,
                }) 
            };
          
            const response = await fetchWithDefaults('/medicalForm/deleteCondition', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const removeFormMedication= async (med,indexToRemove)=>{
        setMedicalForm(prevMedicalForm => prevMedicalForm.filter((_, index) => index !== indexToRemove));

        try {
            const requestOptions = {
                method: 'DELETE',
                body: JSON.stringify({ 
                    medication:med,
                }) 
            };
          
            const response = await fetchWithDefaults('/medicalForm/deleteMedications', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const fetchMedForm= async ()=>{
        try {
            const response = await fetchWithDefaults(`/medicalForm/fetchMedForm?lead=${lead[0].lead_id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            const extractValues = (dataArray) => {
                return dataArray
                .filter(item => item.vm_id !== null)  // Filter out items with null vm_id
                .map(item => ({
                    parent_id: item.parent_id,
                    id: item.vm_id,
                    answer: item.answer
                }));
            };
            const extractedValues = extractValues(jsonData);

            setProfile(prevProfile => ({
                ...prevProfile,
                qualifiers: extractedValues
            }));
            
            setMedicalForm(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const [searchMed, setSearchMed] = useState(false);
    const handleOpenSearch=()=>{
        setSearchMed(true);
    }
    const addCondition=(condition)=>{
        if (conditions && !conditions.includes(condition)) {
            setPromptQuestion(JSON.parse(condition.question));
            setCurrentPendingCondition(condition);
            setConditions(prevState => [...prevState, condition]);
        }
    }
    const removeMed=(indexToRemove)=>{
        setUsedMedications(prevState => prevState.filter((_, index) => index !== indexToRemove));
    }
    const removeCondition=(indexToRemove)=>{
        setConditions(prevState => prevState.filter((_, index) => index !== indexToRemove));
    }
    const addUsedMedications=async (med)=>{

        if (!usedMedications.includes(med)) {
            setUsedMedications(prevState => [...prevState, med]);
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({ 
                        medication:med,
                        form:lead[0].medicalForm,
                        agent:agentId,
                        lead:lead[0].lead_id
                    }) 
                };
              
                const response = await fetchWithDefaults('/medicalForm/medicalFormChange', requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();

                setLead('medicalForm',jsonData.form)
                setUsedMedications([]);
                fetchMedForm();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
    const getHeight = () => {
        if(innerWrapperRef.current && innerWrapperRef.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    }; 
    const getHeight2 = () => {
        if(innerWrapperRef2.current && innerWrapperRef2.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef2.current) {
            return innerWrapperRef2.current.clientHeight;
        }
        return 0;
    }; 
    const getHeight3 = () => {
        if(innerWrapperRef3.current && innerWrapperRef3.current.clientHeight===0){
            return 0
        }
        if (innerWrapperRef3.current) {
            return innerWrapperRef3.current.clientHeight;
        }
        return 0;
    }; 
    const handleAnswer=(newQualifier)=>{
        setCurrentAnswer(newQualifier);
        setProfile(prevProfile => {
            const existingIndex = prevProfile.qualifiers.findIndex(
                qualifier => qualifier.parent_id === newQualifier.parent_id
            );

            let updatedQualifiers;

            if (existingIndex !== -1) {
                // Overwrite existing qualifier
                updatedQualifiers = [...prevProfile.qualifiers];
                updatedQualifiers[existingIndex] = newQualifier;
            } else {
                // Add new qualifier
                updatedQualifiers = [...prevProfile.qualifiers, newQualifier];
            }

            return {
                ...prevProfile,
                qualifiers: updatedQualifiers
            };
        });
    }
    useEffect(() => {
        fetchMedForm();
    }, []);
    useEffect(() => {
        runVM(profile, setResults, lead[0].lead_id);
    }, [profile]);
    const handleSetProfile= (column, value)=>{
        setProfile(prevProfile => ({...prevProfile,[column]:value}));
    }
    const isQuestionSelected = (question) => {
        return profile.qualifiers.some(qualifier => qualifier.id === question.id);
    };
    return (
        <div className="quilityNestedBlockWrapper" style={{ height: isOpen && title !== 'Lead Qualifier Information' && getHeight() !== 0 ? 'fit-content' : title === 'Lead Qualifier Information' ? 'fit-content' : '35px' }}>
            <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick()}>
                <div className="quilityParentBlockTitle">{title}</div>
                <span className={`quilityBlockIcon ${isOpen ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
            </div>
            <div className="quilityNestedBlockContent"  ref={innerWrapperRef} style={{margin:'20px 0px'}}>
                    <div className="quilityNestedBlockContent" ref={innerWrapperRef2} style={{border: 'solid 1px #f1f1f1'}}>
                        <QualifierSection handleSetProfile={handleSetProfile} agentId={agentId} setLead={setLead} lead={lead} data={leadMainData}/>
                    </div>

                {title==='Mortgage Info' ? (
                    null
                ) : (
                    <div className="quilityNestedBlockWrapper"  style={{ height: isOpen3 && getHeight3() !==0 ? 'fit-content' : '35px', marginTop:'15px'}}>
                        <div className="quilityNestedBlockHeader" onClick={()=>handleOptionClick3()} style={{backgroundColor:'var(--quility-block-header-background-color)'}}>
                            <div className="quilityParentBlockTitle">Medications and Conditions</div>
                            <span className={`quilityBlockIcon ${isOpen3 ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
                        </div> 
                        <div className="quilityNestedBlockContent"  ref={innerWrapperRef3} style={{border: 'solid 1px #f1f1f1', textAlign:'center', height:'fit-content', paddingBottom:'10px',backgroundColor:'var(--quility-block-header-background-color)'}}>
                            <div className="qualifierSection" style={{display:'flex',justifyContent: 'space-around'}}>
                                <Medication removeFormMedication={removeFormMedication} medicalForm={medicalForm} removeMed={removeMed} usedMedications={usedMedications} handleOpenSearch={handleOpenSearch}  searchMed={searchMed}/>
                                <Condition handleSetProfile={handleSetProfile} removeFormCondition={removeFormCondition} medicalForm={medicalForm} removeCondition={removeCondition} conditions={conditions} addCondition={addCondition}/>
                            </div>
                            <MedicationInfo addUsedMedications={addUsedMedications} addCondition={addCondition} medication={'ozempic'}/>
                            <ConditionInfo possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} addCondition={addCondition} medication={'ozempic'}/>
                        </div>
                    </div> 
                )}
            </div>
            {promptQuestion && (
                <div className="conditionQuestionWrapper">
                    <div className="conditionQuestionContent">
                        {promptQuestion.map((question, index) => {
                            const isSelected = isQuestionSelected(question);
                            return (
                                <div
                                    className={`qualifierFieldWrapper ${isSelected ? 'selectedQuestion' : ''}`}
                                    key={`conditionQuestion${index}`}
                                    style={{ width: '100%',cursor:'pointer'}}
                                >
                                    <div
                                        className="qualifierFieldQuestionTitle"
                                        onClick={() => handleAnswer(question)}
                                    >
                                        {question.text}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="buttonWrapper" style={{textAlign:'center'}}>
                            <div className="saveAsQueue solidQuilityDestructiveButton" onClick={()=>setPromptQuestion(false)}>Cancel</div>
                            <div className="saveAsQueue solidQuilityActiveButton" onClick={()=>saveConditionQuestion(currentAnswer)}>Save</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

}

export default QualifierBlock;