import { createSlice } from '@reduxjs/toolkit'

export const filterSearchSlice = createSlice({
    name: 'filterSearch',
    initialState: {
        search: {},
        selectedOptions:[]
    },
    reducers: {
        clearSelectedOption(state, action) {
            state.selectedOptions = action.payload;
        },
        toggleSelectedOption(state, action) {
            const option = action.payload; // Option object to toggle, e.g., { option: 'state', search: 'IN' }

            // Check if the option already exists in selectedOptions
            const exists = state.selectedOptions.some(
                item => item.option === option.option && item.search === option.search
            );

            if (exists) {
                // Remove the option if it exists
                state.selectedOptions = state.selectedOptions.filter(
                    item => !(item.option === option.option && item.search === option.search)
                );
            } else {
                // Add the option if it doesn't exist
                state.selectedOptions.push(option);
            }
        },
        toggleSearch(state, action) {

            const { search, option, id } = action.payload;
            // Check if the item already exists in the search array
            console.log(action.payload)
            if (!state.search[search]) {
                // Initialize the array for this key if it doesn't exist
                state.search[search] = [];
            }

            // Check if the value already exists in the array for this key
            const exists = state.search[search].includes(option);

            if (exists) {
                // Remove the value if it exists
                state.search[search] = state.search[search].filter(item => item !== option);

                // Remove the key from the object if the array becomes empty
                if (state.search[search].length === 0) {
                    delete state.search[search];
                }
            } else {
                // Add the value if it doesn't exist
                state.search[search].push(option);
            }
        }
    },
})
export const { toggleSelectedOption,toggleSearch, clearSelectedOption } = filterSearchSlice.actions;

export default filterSearchSlice.reducer

