

import React, { useState, useEffect } from 'react';
import DispositionBlock from './sections/dispositions';
import StartSession from './startSession';
import { useTimer } from './callTimer';
import { useSelector, useDispatch } from 'react-redux';
import { setRingState } from '../../../features/callSlice';

function Dial({handleLeadData, addTag, device, from, theCall, handlCallAccepted, setCallState, makeDial, dialAmount, currentLead,incrementQueue}) { 
    const [currentDial, setCurrentDial] = useState(1);
    const [disposition, setDisposition] = useState(null);
    //const [toCall, setToCall] = useState(null);
    const dispatch = useDispatch();
    const { seconds, clearTimer, resetTimer } = useTimer(null, () => {
        device.disconnectAll();
        setTimeout(() => {
            if(currentDial < dialAmount){
                resetTimer(16);
                makeDial(toCall); 
                setCurrentDial(currentDial+1);
            }
            else if(currentDial === dialAmount){
                setCurrentDial(currentDial+1);
                makeDial(toCall); 
                clearTimer();
            }
            else{
                clearTimer();
            }
        }, 2500);
    });
    const [sessionStarted, setSessionStarted] = useState(false);
    const [click, setClick] = useState(false);

    function getFirstNumber(number1, number2, number3) {
        if (number1 !== null && number1 !== undefined) {
            return number1;
        }
        if (number2 !== null && number2 !== undefined) {
            return number2;
        }
        if (number3 !== null && number3 !== undefined) {
            return number3;
        }
        return null;
    }
    const toCall=getFirstNumber(currentLead.borrower_cell,currentLead.borrower_home,currentLead.borrower_work);
    useEffect(() => {
        if(currentLead && sessionStarted){
            if(!click){
                if(dialAmount > 1){
                    resetTimer(18);
                    setCurrentDial(2);
                    dispatch(setRingState('Ringing...'));
                    makeDial(toCall);
                }
                else{
                    makeDial(toCall); 
                    dispatch(setRingState('Ringing...'));
                }
            }
        }

        return () => {
          // Do something when the component unmounts
        };
    }, [sessionStarted,currentLead.lead_id, click]);
  
    useEffect(() => {
        
        if(theCall){
            theCall.on("accept", () => {
                dispatch(setRingState('Connected'));
                clearTimer();
            });
            theCall.on("disconnect", () => {
                dispatch(setRingState('Disconnected'));
                clearTimer();
            });
        }
    }, [theCall]);
   
    const handleStartSession=()=>{
        setSessionStarted(true);

    }
    const clickToDial=()=>{
        setSessionStarted(true);
        setClick(true);
    }

    return (
        <>
            {sessionStarted===false && (
                <StartSession clickToDial={clickToDial} handleStartSession={handleStartSession} />
            )}
            <div className="dispositionHolder">
                <DispositionBlock setDisposition={setDisposition} handleLeadData={handleLeadData} addTag={addTag} setCurrentDial={setCurrentDial} tags={currentLead.tag_ids} from={from} theCall={theCall} incrementQueue={incrementQueue} queuePos={currentLead.queuePos} click={click} leadId={currentLead.lead_id}  handlCallAccepted={handlCallAccepted} />
            </div>
        </>
    );
}

export default Dial;
//