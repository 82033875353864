import { configureStore } from '@reduxjs/toolkit'
import agentReducer from '../features/agentSlice';
import convoFlowReducer from '../features/conversationFlowSlice';
import leadTypeReducer from '../features/leadTypeSlice';
import objectionReducer from '../features/objectionsSlice';
import reminderReducer from '../features/remindersSlice';
import scopeReducer from '../features/scopesSlice';
import scriptReducer from '../features/scriptsSlice';
import stateReducer from '../features/leadStateSlice';
import statusReducer from '../features/statusSlice';
import tagReducer from '../features/tagSlice';
import textReducer from '../features/textSlice';
import variableReducer from '../features/variableSlice';
import workflowReducer from '../features/workflowSlice';
import conversationReducer from '../features/conversationSlice';
import filterReducer from '../features/filterSlice';
import queueReducer from '../features/queueSlice';
import loadingReducer from '../features/loadingSlice';
import emailReducer from '../features/emailSlice';
import missedCallReducer from '../features/missedCallSlice';
import topBarReducer from '../features/topBarSlice';
import callReducer from '../features/callSlice';
import popupsReducer from '../features/popupSlice';
import voicemailReducer from '../features/voicemailSlice';
import filterSearchReducer from '../features/leadFilterSlice';


export default configureStore({
    reducer: {
        agent: agentReducer,
        convoFlow: convoFlowReducer,
        leadType: leadTypeReducer,
        objections: objectionReducer,
        reminders: reminderReducer,
        scopes: scopeReducer,
        scripts: scriptReducer,
        states: stateReducer,
        status: statusReducer,
        tags: tagReducer,
        texts: textReducer,
        variables: variableReducer,
        workflows: workflowReducer,
        conversations: conversationReducer,
        filters: filterReducer,
        queue: queueReducer,
        loading: loadingReducer,
        email: emailReducer,
        missedCall: missedCallReducer,
        topBar: topBarReducer,
        call: callReducer,
        popups: popupsReducer,
        voicemails: voicemailReducer,
        filterSearch:filterSearchReducer,
    },
})