
import { createSlice } from '@reduxjs/toolkit'

export const voicemailSlice = createSlice({
    name: 'voicemails',
    initialState: {
        voicemails: [],
        unheard:0
    },
    reducers: {
        setVoicemails(state, action) {
            state.voicemails = action.payload;
        },
        addVoicemail(state, action) {
            if (state.voicemails.length > 0) {
                state.voicemails.unshift(action.payload); // Adds to the beginning of the array
            }
        },
        setUnheard(state, action) {
            state.unheard += action.payload;
        },
    },
})
export const { setVoicemails, setUnheard, addVoicemail } = voicemailSlice.actions;

export default voicemailSlice.reducer

