

import React, {useState, useEffect} from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';
import image from '../../assets/icons/loading-loader.gif';
import { createNewCustomer } from '../../utilities/marketUtil';
import AddCard from '../subscription/addCard';


function PurchasePage({title, data}) {
    const agentInfo=useSelector((state) => state.agent.userData);

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [unitPrice, setUnitPrice] = useState(false);
    const [filteredStates, setFilteredStates] = useState([]);
    const [purchaseError, setPruchaseError] = useState(false);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedCard, setSelectedCard] = useState(false);
    const [vendor, setVendor] = useState(false);
    const [create, setCreate] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [cards, setCards] = useState(false);
    const [customer, setCustomer] = useState(false);

    const states = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
        'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
        'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
        'West Virginia', 'Wisconsin', 'Wyoming'
    ];
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setFilteredStates(states.filter(state => state.toLowerCase().includes(value.toLowerCase())));
    };
    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };
    const selectState = (state) => {
        if (!selectedStates.includes(state)) {
            setSelectedStates([...selectedStates, state]);
            setInputValue('');
        }
    };
    const handleRemove = (stateToRemove) => {
        setSelectedStates(selectedStates.filter(state => state !== stateToRemove));
    };
    const fetchCards = async () => {
        try {
            const response = await fetchWithDefaults(`/subscription/paymentInfo?agent=${agentInfo.agentId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            if(jsonData.status===false){
                if(jsonData.message==='na'){
                    //setCreate(true);
                    const customer=await createNewCustomer(agentInfo.agentId);
                    setCustomer(customer)
                    setCreate(true);
                }
            }
            else{
                setCards(jsonData.payment.data);
                setCustomer(jsonData.customer);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }; 
    const submitOrderForm = async () => {
        setLoading(true)
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                agent: agentInfo.agentId,
                agentFirst: agentInfo.firstName,
                agentLast: agentInfo.lastName,
                agentEmail:agentInfo.email,
                card: selectedCard,
                vendor: vendor.vendor_id,
                amount: (unitPrice * quantity).toFixed(2),
                order: quantity,
                customer:customer,
                states: selectedStates,
                leadType: vendor.type,
                subType: vendor.sub_type,
                orderType: 'single',
                leadTypeId: vendor.id,
                priceObj: vendor.price_obj,
                workflow: 'aaa'
            }) 
        };
        try {
            const response = await fetchWithDefaults(`/market/submitOrder`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLoading(false);
            setPruchaseError(jsonData.message);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        
    }; 
    useEffect(() => {
        setVendor(data)
        fetchCards();
        if(data){
            setUnitPrice(data.price)
        }
    },[])
    const handleChangeCard=(card)=>{
        if(card==='none'){
            setSelectedCard(false)
        }
        else if(card==='new'){
            setCreate(true);
        }
        else{
            setSelectedCard(card);
        }
    }
    const addACardDone = () => {
       setCreate(false);
       fetchCards();
    };
    return (
        <div className="purchasePageWrapper">
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper">
                        <img src={image} alt="Loading Gif" style={{width: '100%'}} />
                    </div>
                </div>
            )}
            {create && (
                <div className="addCardPopup">
                    <AddCard cust={customer} addACardDone={addACardDone} />
                </div>
            )}
            <div className="blockWrapper">
                <div className="blockHeader">
                    <div className="title" style={{margin:'unset'}}>Purchase Form - {title}</div>
                </div>

                <div className="blockContent">
                    <div className="purchaseSectionWrapper">
                        <div className="price">Price Per Lead: {unitPrice}</div>
                        <div className="purchaseSectionTitle">States (5 minimum)</div>
                        <div className="inputWrapper">
                            <input
                                type="text"
                                className="qualifierFieldInput"
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder='Type State'
                                style={{width: '300px'}}
                            />
                            {inputValue !='' && inputValue && (
                                <div className="stateOptionsList">
                                    {filteredStates.map((state, index) => (
                                        <div key={index} className="option" onClick={()=>selectState(state)}>{state}</div>
                                    ))}
                                </div>
                            )}
                            
                        </div>
                    </div>
                    <div className="purchaseSectionWrapper">
                        {selectedStates && (
                            selectedStates.map((state, index) => (
                                <div key={`selectedState`+index} className="removeableOption">
                                    <span className="removeableRemove" onClick={()=>handleRemove(state)}>X</span>
                                    <span className="removeableTitle">{state}</span>
                                </div>

                            ))
                        )}
                    </div>
                    <div className="purchaseSectionWrapper">
                        <div className="purchaseSectionTitle">Order Quantity (5 minimum)</div>
                        <div className="inputWrapper">
                            <input 
                                type="text" 
                                className="qualifierFieldInput" 
                                style={{width: '300px'}}
                                value={quantity} 
                                onChange={handleQuantityChange}
                            />
                        </div>
                    </div>
                    
                    {selectedStates.length > 4 && parseInt(quantity) > 4 && (
                        <div className="submitWrapperForm" style={{textAlign:'center'}}>
                            <div className="totalPrice">Order Total: <b>{(unitPrice * quantity).toFixed(2)}</b></div>
                            <div className="selectCards">
                                <select className="qualifierFieldInput" 
                                    style={{width: '100%',height: '30px',fontFamily: 'inherit',fontSize: '13px',color: 'inherit'}}
                                    onChange={(e)=>handleChangeCard(e.target.value)}
                                    value={selectedCard}
                                >
                                    <option value="none">Select Your Payment Card</option>
                                    {cards && cards.map((card, index) => (
                                        <option value={card.id}>{card.card.brand} Ending in {card.card.last4}</option>
                                    ))}
                                    <option value='new'>Add a new card</option>
                                </select>
                            </div>
                            {selectedCard && (
                                <div className="buttonWrapper">
                                    {purchaseError && (
                                        <div className="error">{purchaseError}</div>
                                    )}
                                    <div className="orderButton solidQuilityActiveButton" onClick={()=>submitOrderForm()}>
                                        Submit Order
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PurchasePage;
