import { fetchWithDefaults } from '../config/fetchDefault';
import { setUserData, setPhoneNumbers, setActiveCalendar, setTextNumbers,setLogo } from '../features/agentSlice';
import { setConvoFlow } from '../features/conversationFlowSlice';
import { setStates } from '../features/leadStateSlice';
import { setLeadType } from '../features/leadTypeSlice';
import { setObjections } from '../features/objectionsSlice';
import { setReminders } from '../features/remindersSlice';
import { setScopes } from '../features/scopesSlice';
import { setScripts } from '../features/scriptsSlice';
import { setStatus } from '../features/statusSlice';
import { setTags } from '../features/tagSlice';
import { setTexts } from '../features/textSlice';
import { setVariables } from '../features/variableSlice';
import { setWorkflows } from '../features/workflowSlice';
import { setConversations } from '../features/conversationSlice';
import { setEmails } from '../features/emailSlice';
import { setPopups } from '../features/popupSlice';
import { setFilters, setActiveFilterName } from '../features/filterSlice';

const setActiveFilter = (name, dispatch) => {
    dispatch(setActiveFilterName(name));
}
const fetchAppInformation = async (setAppInfo, dispatch, agentIdNumber, organization) => {
    try {
        const response = await fetchWithDefaults(`/appInfo?agent=${agentIdNumber}&organization=${organization}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        dispatch(setPopups(jsonData[0].popups));
        dispatch(setActiveCalendar(jsonData[0].agent[0].defaultCalendar));
        dispatch(setUserData(jsonData[0].agent[0]));
        dispatch(setPhoneNumbers(jsonData[0].agent[1]));
        dispatch(setConvoFlow(jsonData[0].convoFlow));
        dispatch(setLeadType(jsonData[0].leadTypes));
        dispatch(setObjections(jsonData[0].objections));
        dispatch(setStates(jsonData[0].states));
        dispatch(setReminders(jsonData[0].reminders));
        dispatch(setScopes(jsonData[0].scopes));
        dispatch(setScripts(jsonData[0].scripts));
        dispatch(setStatus(jsonData[0].status));
        dispatch(setTags(jsonData[0].tags));
        dispatch(setTexts(jsonData[0].texts));
        dispatch(setVariables(jsonData[0].variables));
        dispatch(setWorkflows(jsonData[0].workflows));
        dispatch(setEmails(jsonData[0].emails));
        setAppInfo(jsonData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
const fetchActivity = async (agentIdNumber,setActivity) => {

    try {
        const response = await fetchWithDefaults(`/Activity?agent=${agentIdNumber}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setActivity(jsonData);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
}
const gatherTheme = async (tenant) => {

    try {
        const response = await fetchWithDefaults(`/appInfo/theme?tenant=${tenant}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    
}
const applyTheme= async (theme,root,dispatch)=>{
    Object.keys(theme[0]).forEach(key => {
        if(key==='logo'){
            dispatch(setLogo(theme[0][key]));
        }
        if (theme[0][key] !== null && key.startsWith('--')) {
          root.style.setProperty(key, theme[0][key]);
        }
    });
}
const getConversations = async (handleSetUnread, agentIdNumber, dispatch) => {
    if(agentIdNumber!==false){

        try {
            const response = await fetchWithDefaults(`/conversation/getConvos?agent=${agentIdNumber}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let unreadMessageCount = 0;
            const jsonData = await response.json();
            // Iterate through the JSON data and count the objects with message_read = 'no'
            if(jsonData.length !== undefined){
                jsonData.forEach(obj => {
                    if (obj.message_read === 'no') {
                        unreadMessageCount++;
                    }
                });
            }
            handleSetUnread(parseInt(unreadMessageCount));
            if(!jsonData.status){
                if(jsonData.status !== false){
                    dispatch(setConversations(jsonData));
                }
            }
            

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
}


const fetchFilterData = async (dispatch,agentIdNumber) => {

    const response = await fetchWithDefaults('/filters/get?agent='+agentIdNumber);
    if (!response.ok) {

        throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();
    if(jsonData.length > 0){
        dispatch(setFilters(jsonData));
    } 
};
const saveTextingNumbers = async (dispatch, numbers) => {
    dispatch(setTextNumbers(numbers));
}

const getComponent = async (agentInfo, path, setData) => {
    let pathExtension;
    if(path.substr(0,5)=='batch'){

        pathExtension='?batch='+path;
        path='Viewing_Batch';
    }
    else{
        pathExtension=`?agent=${agentInfo.agentId}&organization=${agentInfo.organization}`;
        path=path;
    }
    try {
        console.log('/'+path+pathExtension);
        const response = await fetchWithDefaults('/'+path+pathExtension);
            if (!response.ok) {
                //dispatch(setRefreshLogin(true));
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            console.log(jsonData);
            if(jsonData.status==false){
                setData([{type:false}]);
            }
            else{
               setData(jsonData)
            }

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const decrypt = async (string,applyTheme,gatherTheme,dispatch,setAgentIdNumber,setSignedIn,saveData) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ 
                cipher: string,
                source:'web'
            }) 
        };

        const response = await fetchWithDefaults('/login/decrypt', requestOptions);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();
        if(jsonData[0].status===true){
          await saveData(jsonData[0].cipher);
          setAgentIdNumber(jsonData[0].id)
          const root = document.documentElement;
          const theme=await gatherTheme(jsonData[0].organization);
          await applyTheme(theme,root,dispatch);
          setSignedIn(true);
          let admin=false;
          if(jsonData[0].admin===1){
            admin=true;
          } 
          dispatch(setUserData({
            agentId: jsonData[0].id,
            organization: jsonData[0].organization,
            admin:admin,
            organizationName:jsonData[0].orgName,
            permissions:jsonData[0].permissions
          }))
        }
        
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const finishTutorial = async (agent) => {
    try {
        const requestOptions = {
            method: 'PATCH',
            body: JSON.stringify({ 
                agent: agent,
            }) 
        };

        const response = await fetchWithDefaults('/appInfo/tutorial', requestOptions);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const SSO = async (query) => {
    const params = new URLSearchParams(query);
    const code = params.get('code');
    const state = params.get('state');
    try {
        const response = await fetchWithDefaults(`/quility/sso?code=${code}&state=${state}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
export {finishTutorial,decrypt,fetchAppInformation, getConversations, fetchFilterData, fetchActivity, setActiveFilter, saveTextingNumbers,gatherTheme,applyTheme,getComponent,SSO}