import React, {useState, useEffect} from 'react';
import '../../css/leadCard.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import LeadData from '../conversations/leadData';
import QueueScripts from '../callQueue/elements/scripts';
import QueueConvo from '../callQueue/elements/conversation';
import QueueNotes from '../callQueue/elements/notes';
import Recommendation from '../callQueue/elements/sections/recommendation';
import RightQueueHeader from '../callQueue/elements/rightQueueHeader';
import Email from '../email/email';
import { UnixTimestampToDate } from '../../utilities/timeFunctions';
import Icon from '../../assets/icons/icon';
import { useDispatch, useSelector } from 'react-redux';
import { setCall } from '../../features/callSlice';
import LeadCallRecordings from './leadRecording';
import Scripting from '../scripting/scripting';
import Qualifier from '../qualifier/qualifier';




function LeadCard({handleUpdateQueueLead,setLoading, data, textingNumbers,handleSoftPhone, device, setCallerId, maxList, newAppt, setNewAppt, permission}) {
    const dispatch=useDispatch();
    const [flowData, setFlowData] = useState(false);
    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const [scriptType, setScriptType] = useState('dynamic');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [term, setTerm] = useState('30');
    const [faceAmount, setFaceAmount] = useState('100000');
    const [possibleConditions, setPossibleConditions] = useState([]);
    const agentInfo=useSelector((state) => state.agent.userData);
    const [qualifier, setQualifier] = useState(false);
    const [currentScript, setCurrentScript]=useState(null);

    const [results, setResults] = useState(false);
    const [alreadyTagged, setAlreadyTagged] = useState(false);
    const [lead, setLead] = useState(false);
    const [profile, setProfile] = useState();
    const [scripting, setScripting] = useState(false);
    const [selected, setSelected] = useState(1);
    const [conversations, setConversations] = useState([]);
    const selectTab=(index)=>{
        if(index===5){
            setScripting(true)
        }
        else{
            setScripting(false);
        }
        setSelected(index)
    }
    const [newObjection, setNewObjection]=useState(false);
    const handleNewObjection=(objection)=>{
        setNewObjection(objection);
    }
    const agentId=agentInfo.agentId;
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [event, setEvent] = useState(false);

  
    const stopFlow = async (id)=>{
        try {
            const response = await fetchWithDefaults(`/stopFlow?flow=${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(false);
            let index=0;
            setLead(prevData => {
                const newData = [...prevData];
                // Check if index is valid
                if (index >= 0 && index < newData.length) {
                // Update the field value for the object at the specified index
                newData[index] = {
                    ...newData[index],
                    ['wf_name']: null,
                    ['wf_id']: null
                };
                }
                return newData;
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    const handleQualifier=()=>{
        setQualifier(!qualifier);
    }

    
    function transformTags(input) {
        // Split the input string by comma to get individual pairs
        const pairs = input.split(',');
      
        // Transform each pair into the desired format
        const transformedArray = pairs.map(pair => {
          // Split each pair by '*' to get id and name
          const [id, name] = pair.split('*');
          
          return { name, id };
        });
      
        return transformedArray;
    }
    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };
    useEffect(() => {
        //categorizeRows();
        setLead(data)
        setProfile({
            borrower_age:data ? data[0].borrower_age:null,
            state:data ? data[0].state : null,
            feet: data ? data[0].feet : null,
            inches: data ? data[0].inches : null,
            weight: data && data[0].weight ? parseWeight(data[0].weight) : null,
            faceAmount: faceAmount,
            term: term,
            productOption:7,
            qualifiers:[]
        })
    }, []);
    useEffect(() => {
        if(data[0].tag_ids){
            const tag=transformTags(data[0].tag_ids); 
            setAlreadyTagged(tag);
        }

    }, [data[0].tag_ids]);
    const closeData=()=>{
        setFlowData(false);
    }

    const makeDial= async (toNumber, callee, fromNum)=>{

        try {
            // Use the provided `fromNum` or default to `data[0].call_from`
            if (!fromNum) {
                fromNum = data[0].call_from;
            }
    
            // Attempt to connect the call
            const call = await device.connect({
                params: {
                    To: `+1${toNumber}`,
                    callerId: fromNum,
                    user: agentId,
                    tone: "default",
                    leadId: data[0].lead_id
                }
            });
            // Handle the call if the connection is successful
            dispatch(setCall(call));
            handleSoftPhone(call.status());
            setCallerId({ name: callee, lead_id: data[0].lead_id, outbound_number:fromNum, last_contact: data[0].last_contacted });
        } catch (error) {
            // Log the error for debugging purposes
            console.error("Error making the call:", error);
    
            // Handle different types of errors accordingly
            if (error.message.includes('Twilio')) {
                console.error("Twilio-related error:", error.message);
                // Handle Twilio-specific errors
                alert("Failed to connect the call. Please check the phone number and try again.");
            } else if (error.message.includes('Network')) {
                console.error("Network-related error:", error.message);
                // Handle network-related errors
                alert("Network issue. Please check your internet connection and try again.");
            } else {
                console.error("Unexpected error:", error.message);
                // Handle other unexpected errors
                alert("An unexpected error occurred. Please try again later.");
            }
    
            // Optionally, you could retry the call or take other actions depending on the error
        }
    }
    const newEventPackage=[{setNewEvent:setNewEvent, setTime:setTime, setDate:setDate}]
    const [timestamp, setTimestamp] = useState(null);
    const handleLeadData=(field, value)=>{
        let index=0;
        setLead(prevData => {
            const newData = [...prevData];
            // Check if index is valid
            if (index >= 0 && index < newData.length) {
              // Update the field value for the object at the specified index
              newData[index] = {
                ...newData[index],
                [field]: value
              };
            }
            return newData;
        });
        handleUpdateQueueLead(field,value,lead[0].lead_id)
    }
    const handleScriptType=(type)=>{
        setScriptType(type)
    }
    const handleProfileUpdate=(key, value)=>{
        setProfile(prevState => ({
            ...prevState,
            [key]: value
        }));
    }
    const handleSetConversations=(convos)=>{
        setConversations(convos)
    }
    const handleSelectTab=(tab)=>{
        if(tab===4){
            setQualifier(true);
            selectTab(tab)
        }
        else if(tab===0){
            selectTab(4)
            setScripting(true);
        }
        else{
            selectTab(tab)
        }
    }
    const returnToLead=()=>{
        setScripting(false);
        setQualifier(false);
    }
    const handleSetQualifier=()=>{
        setQualifier(false)
    }
    const expiration=(parseInt(data[0].exclusivity) - parseInt(timestamp));
    return (
        <div className="leadCardWrapper">
            <div className="quilityCloseWindow" onClick={() => maxList()}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
            {/*<LeadCardTopBar expiration={expiration} setLoading={setLoading} textingNumbers={textingNumbers} qualifier={qualifier} handleQualifier={handleQualifier} calendarChange={calendarChange} setCalendarUsing={setCalendarUsing} setEvent={setEvent} newEventPackage={newEventPackage} newAppt={newAppt} setNewAppt={setNewAppt} setNewEvent={setNewEvent} data={data}/>*/}
            {lead && (
                <>
                {data[0].parent_status==='client' ? (
                    <div className="leadCardDataWrapper">
                    <div className="centerQueue">
                        
                        <LeadData setQualifier={setQualifier} selectTab={selectTab} profile={profile} setProfile={setProfile} setFlowData={setFlowData} handleLeadData={handleLeadData} newEventPackage={newEventPackage}  setNewAppt={setNewAppt} newAppt={newAppt} expiration={expiration} alreadyTagged={alreadyTagged} qualifier={qualifier} makeDial={makeDial} lead={lead} handleQualifier={handleQualifier}/>
                    </div>
                    <div className="rightQueue">
                        <RightQueueHeader setScripting={setScripting} selected={selected} selectTab={selectTab}/>
                        {selected==0 ? (
                            <QueueScripts handleNewObjection={handleNewObjection} currentLead={lead[0]} scriptId={currentScript}/>
                        ) : selected==1 ? (
                            //ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue})
                            <QueueConvo  handleLeadData={handleLeadData} expiration={expiration} setLoading={setLoading} currentLead={lead[0]} lead={lead[0]} textingNumbers={textingNumbers} handleSetConversations={handleSetConversations} conversations={conversations} agentId={agentId} teamId={agentInfo.teamId} currentLeadId={lead[0].lead_id} />
                        ) : selected==2 ?(
                            expiration > 0 || isNaN(expiration) ? (
                                <Email email={lead[0].email} setIsEmailOpen={setIsEmailOpen}/>
                            ) : (
                                <div className="noResults">You can no longer Email this lead</div>
                            )
                        ) : selected==3 ?(
                            <QueueNotes notes={lead[0].notes} leadId={lead[0].lead_id} agentId={agentId} plan={lead[0].policy_plan}/>
                        ) : (
                            <Recommendation selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} results={results} lead={lead}/>
                        )}
                    </div>
                </div>
                ):(   
                    <div className="leadCardDataWrapper">
                        <div className="centerQueue">
                            {scripting && (
                                <>
                                    <div className="recLink solidQuilityActiveButton" style={{position:'absolute', top:'10px'}} onClick={()=>returnToLead()}>Return</div>
                                    <div className="productTabs" style={{marginTop:'45px'}}>
                                        <div className={`productTab ${scriptType==='dynamic' && 'selectedTab'}`} onClick={()=>handleScriptType('dynamic')}>Dynamic Script</div>
                                        <div className={`productTab ${scriptType==='qual' && 'selectedTab'}`} onClick={()=>handleScriptType('qual')}>Qualifier Q's</div>
                                    </div>
                                </>
                            )}
                            {scripting && !qualifier? (
                                scriptType==='dynamic' ? (
                                    <Scripting selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} setResults={setResults} profile={profile} setProfile={setProfile} lead={lead} handleLeadData={handleLeadData}/>
                                ) : ( 
                                    <span className="qualifierAdjustment">
                                        <Qualifier from={'script'} possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} setProfile={setProfile} profile={profile} setResults={setResults} handleLeadData={handleLeadData} handleSetQualifier={handleSetQualifier} lead={lead} agentId={agentId}/>
                                    </span>
                                )
                            ) : ( 
                                <LeadData permission={permission} selectTab={selectTab} setQualifier={setQualifier} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} possibleConditions={possibleConditions} setPossibleConditions={setPossibleConditions} profile={profile} setProfile={setProfile} setResults={setResults} setFlowData={setFlowData} handleLeadData={handleLeadData} newEventPackage={newEventPackage} setEvent={setEvent} setNewAppt={setNewAppt} newAppt={newAppt} expiration={expiration} alreadyTagged={alreadyTagged} qualifier={qualifier} makeDial={makeDial} lead={lead} handleQualifier={handleQualifier}/>
                            )}
                            
                        </div>
                        <div className="rightQueue">
                            <RightQueueHeader recording={lead[0].recording_count} selected={selected} handleSelectTab={handleSelectTab} setQualifier={setQualifier}/>
                            {selected==0 ? (
                                <QueueScripts handleNewObjection={handleNewObjection} currentLead={lead[0]} scriptId={currentScript}/>
                            ) : selected==1 ? (
                                //ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue})
                                <QueueConvo handleLeadData={handleLeadData} expiration={expiration} setLoading={setLoading} currentLead={lead[0]} lead={lead[0]} textingNumbers={textingNumbers} setConversations={setConversations} conversations={conversations} agentId={agentId} teamId={agentInfo.teamId} currentLeadId={lead[0].lead_id} />
                            ) : selected==2 ?(
                                expiration > 0 || isNaN(expiration) ? (
                                    <Email email={lead[0].email} setIsEmailOpen={setIsEmailOpen}/>
                                ) : (
                                    <div className="noResults">You can no longer Email this lead</div>
                                )
                            ) : selected==3 ?(
                                <QueueNotes notes={lead[0].notes} leadId={lead[0].lead_id} agentId={agentId} plan={lead[0].policy_plan}/>
                            ) : selected==6 ?(
                                <LeadCallRecordings lead={lead[0].lead_id}/>
                            ) : (
                                <Recommendation selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} handleProfileUpdate={handleProfileUpdate} setFaceAmount={setFaceAmount} faceAmount={faceAmount} term={term} setTerm={setTerm} possibleConditions={possibleConditions} results={results} lead={lead} profile={profile}/>
                            )}
                        </div>
                    </div>
                )}
                
                {newEvent &&(
                    null
                )}
                </>
            )}
            {flowData && (
                <div className="flowDataWrapper">
                    <div className="flowDataContent">
                        <div className="closeWindow" onClick={()=>closeData()}>X</div>
                        {flowData.map((item, index) => (
                            <>
                                <div className="title">Workflow Info</div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Name:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.name}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Date Started:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {UnixTimestampToDate(item.start)}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Length:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.totalResult} Actions
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Actions Remaining:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.activeCount}
                                    </div>
                                </div>
                                <div className="buttonWrapper">
                                    <div className="accentButton" style={{margin:'auto', marginTop:'25px'}} onClick={()=>stopFlow(item.theId)}>Stop Workflow</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LeadCard;

//