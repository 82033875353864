


import React from 'react';
import '../../css/queue.css';
import DialAmount from './elements/dialAmount';
import StaticPhone from '../phone/staticPhone';
import PhoneNumbers from './elements/phoneNumbers';
import Icon from '../../assets/icons/icon';



function DialModule({ call, callState, expanded, dialAmount, setSelectedNumber, amountSelector, device,selectedNumber, currentLead, expandSession, setDialAmount, openDialAmount,handlCallAccepted}) {

    
    return (
        <div className="stuck">
            {expanded ? (
                <>
                    {dialAmount ? (
                       null
                    ):(
                        <div className="dialOptionsHolder">
                            <PhoneNumbers selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber}/>
                            <DialAmount setDialAmount={setDialAmount}/>
                        </div>
                    )}
                   <div style={{}}><div className="currentDialAmount" style={{lineHeight:' 40px', textAlign:'center',flex: '1', fontWeight:'700'}}>
                    {dialAmount > 1 ? (
                        <>  
                            {amountSelector ? (
                               <>
                                    <span className="dialAmountSelectBox" onClick={()=>openDialAmount()}>
                                        {dialAmount}
                                        <div className="dialAmountWrapper">
                                            <div className="dialAmountOption" onClick={()=>setDialAmount(1)}>1</div>
                                            <div className="dialAmountOption" onClick={()=>setDialAmount(2)}>2</div>
                                        </div>
                                    </span> dial's
                                </>
                            ) : (
                                
                                <div className="dialOptionsHolder">
                                    <div className="queueItemWrapper" style={{width: '55%'}}>
                                        <div className="queueItemTitle">My Number</div>
                                        <PhoneNumbers selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber}/>
                                    </div>
                                    <div className="queueItemWrapper">
                                    <div className="queueItemTitle">Dials</div>
                                        <span className="dialAmountSelect" onClick={()=>openDialAmount()}>
                                            {dialAmount}
                                        </span>Dials
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>  
                            {amountSelector ? (
                                <>
                                    <span className="dialAmountSelectBox" onClick={()=>openDialAmount()}>
                                        {dialAmount}
                                        <div className="dialAmountWrapper">
                                            <div className="dialAmountOption" onClick={()=>setDialAmount(1)}>1</div>
                                            <div className="dialAmountOption" onClick={()=>setDialAmount(2)}>2</div>
                                        </div>
                                    </span> dial
                                </>
                            ) : (

                                <div className="dialOptionsHolder">
                                    <PhoneNumbers selectedNumber={selectedNumber} setSelectedNumber={setSelectedNumber}/>
                                    <span className="dialAmountSelect" onClick={()=>openDialAmount()}>
                                        {dialAmount}
                                    </span> dial
                                </div>
                            )}
                        </>
                    )} 
                    </div> 
                    <div className="dialSessionButtonWrapper" ><div className="startSession quilityLongButton" onClick={()=>expandSession()}>

                        <span className="fontello" style={{marginRight: '5px',lineHeight: '0px'}}><Icon name={'q-pause-circle'} size={20} height='24' className="" adjust="fontello" /></span>
                        Pause Session </div></div></div>
                </>
            ):(
                <div className="dialSessionButtonWrapper"><div className="startSession quilityLongButton" onClick={()=>expandSession()}>
                    <span className="fontello" style={{marginRight: '5px',lineHeight: '0px'}}><Icon name={'q-play-circle'} size={20} height='24' className="" adjust="fontello" /></span>
                    Work Queue </div></div>
            )}

            {device &&(
                <StaticPhone theCall={call} callState={callState} selectedNumber={selectedNumber} handlCallAccepted={handlCallAccepted} device={device} currentLead={currentLead} expanded={expanded}/>
            )}
            
        </div>
    );

}

export default DialModule;
