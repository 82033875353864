import React, {useState} from 'react';
import '../css/topBar.css';
import Block from './block';
import { useSelector } from 'react-redux';


function TopBar(props) {
    const barState=props.barState;
    const [which, setWhich] = useState('Dashboard');
    const agentInfo=useSelector((state) => state.agent.userData);
    function fetchPage(path){
       props.fetchPage(path);
    }
    
    const leads=[
        //{"name":"New Leads",'path':'New_Leads'},
        //{"name":"Active Leads",'path':'Active_Leads'},
        //{"name":"Clients",'path':'Clients'},
        //{"name":"Unresponsive",'path':'Workflow_followup'},
        //{"name":"Duplicate Leads",'path':'template/leads/new'},
        {"name":"Manage Leads",'path':'Add_Leads'},
        //{"name":"Delete Leads",'path':'template/leads/new'},
    ]
    const sync=[
        {"name":"Sync Leads", 'path':'syncLeads'}
    ]
    const todo=[
        {"name":"Follow-Ups",'path':'Follow_Ups'},
        //{"name":"Tasks",'path':'Tasks'},
        //{"name":"Goals",'path':'Goals'},
        {"name":"Calls",'path':'Scheduled_Calls'},
        {"name":"Appointments",'path':'Scheduled_Appointments'},
    ]
    const options=[
        //{"name":"Bulk Workflows",'path':'Bulk_Operations'},
        //{"name":"Bulk Tag",'path':'template/leads/new'},
        //{"name":"Bulk Status",'path':'template/leads/new'},
        //{"name":"Tutorials",'path':'template/leads/new'},
        {"name":"Subscription",'path':'Subscription'}
        //{"name":"Audio Input",'path':'template/leads/new'},
        //{"name":"Export Leads",'path':'template/leads/new'},
    ]

////////////
    const phone=[
        
    ]

    if (agentInfo.beta || agentInfo.status) {
        phone.push(
            {"name":"Trust",'path':'Trust_Hub'},
            {"name":"Numbers",'path':'Number'},
            {"name":"Voicemail",'path':'Voicemail'},
            {"name":"Voice Drops",'path':'VoiceDrop'},
            {"name":"Forwarding",'path':'Call_Forwarding'},
            {"name":"Recording",'path':'Call_Recording'}
        );
    }
    else{
        phone.push(
            {"name":"Trust",'path':'Trust_Hub'},
            {"name":"Voicemail",'path':'Voicemail'},
            {"name":"Recording",'path':'Call_Recording'}
        );
    }
    const leadSettings=[
        //{"name":"Statuses",'path':'Lead_Statuses'},
        {"name":"Tags",'path':'Tags'},
        {"name":"Types",'path':'Lead_Types'}
    ]
    const templates=[
        
    ];
    
    if (agentInfo.admin) {
        templates.push(
            { "name": "Text", 'path': 'Text_Templates' },
            { "name": "Email", 'path': 'Email_Templates' },
            { "name": "Script", 'path': 'Scripts' },
            { "name": "Objection", 'path': 'Objections' }
        );
    } else {
        if (agentInfo.permissions.text === 1) {
            templates.push({ "name": "Text", 'path': 'Text_Templates' });
        }
        if (agentInfo.permissions.email === 1) {
            templates.push({ "name": "Email", 'path': 'Email_Templates' });
        }
        if (agentInfo.permissions.script === 1) {
            templates.push({ "name": "Script", 'path': 'Scripts' });
        }
        if (agentInfo.permissions.objection === 1) {
            templates.push({ "name": "Objection", 'path': 'Objections' });
        }
    }
    const appSettings=[
        {"name":"Integrations",'path':'Integrations'},
        //{"name":"Alerts",'path':'template/leads/new'}
    ]
    
    const dash=[{"name":"Dashboard",'path':'dash'}];
    const team=[{"name":"Teams",'path':'Team'}]
    const market=[{"name":"Marektplace",'path':'Market'}]
    const overview=[{"name":"Overview",'path':'Overview'}]
    //const overview=[]
    const activity=[{"name":"Acitivy",'path':'Activity'}]
    const workflows=[{"name":"Workflows",'path':'Workflows'}];
    const referrals=[]

  return (
        <div className="leftHandBar">
            {barState ? (
                <>
                    <Block title="Dashboard" fetchPage={fetchPage} data={dash} icon='dashboard-q' setWhich={setWhich} which={which}/>
                    <Block title="Lead Settings" fetchPage={fetchPage} data={leadSettings} icon='q-leads' setWhich={setWhich} which={which}/>
                    <Block title="Templates" fetchPage={fetchPage} data={templates} icon='file-chart-q' setWhich={setWhich} which={which}/>
                    {agentInfo.admin && (
                        <Block title="Workflows" fetchPage={fetchPage} data={workflows} icon='q-dataflow-1' setWhich={setWhich} which={which}/>
                    )}
                </>
            ) : (
                <>
                    <Block title="Dashboard" fetchPage={fetchPage} data={dash} icon='dashboard-q' setWhich={setWhich} which={which}/>
                    {agentInfo.organization ==='master' && (
                        (agentInfo.beta || agentInfo.status) ? (
                            <Block title="Sync Leads" fetchPage={fetchPage} icon='repeat-q' data={sync} setWhich={setWhich} which={which}/>
                        ) : (
                            null
                        )
                    )}
                    {agentInfo.organization !== 'master' && (
                        <Block title="Add Leads" fetchPage={fetchPage} data={leads} icon="q-leads" setWhich={setWhich} which={which}/>
                    )}
                    <Block title="To-Dos" fetchPage={fetchPage} data={todo} icon='check-done-01-copy' setWhich={setWhich} which={which}/>
                    <Block title="Team" fetchPage={fetchPage} data={team} icon='q-dataflow-1' setWhich={setWhich} which={which}/>
                    {(agentInfo.beta || agentInfo.status) && (
                        <Block title="Activity" fetchPage={fetchPage} data={activity} icon='activity-q' setWhich={setWhich} which={which}/>
                        
                    )}
                    <Block title="Phone" fetchPage={fetchPage} data={phone} icon='q-phone-call' setWhich={setWhich} which={which}/>
                    {agentInfo.organization !== 'master' && (
                        <>
                            <Block title="Templates" fetchPage={fetchPage} data={templates} icon="file-down-q" setWhich={setWhich} which={which}/>
                            <Block title="Workflows" fetchPage={fetchPage} data={workflows} icon="shuffle-q" setWhich={setWhich} which={which}/>
                        </>
                    )}
                    {(agentInfo.beta || agentInfo.status) && (
                        <Block title="Market" fetchPage={fetchPage} icon='q-shopping' data={market} setWhich={setWhich} which={which}/>
                    )}
                </>
            )}
        </div>
    );
}

export default TopBar;
//<Block title="App Settings" fetchPage={fetchPage} data={appSettings} icon={faGear} setWhich={setWhich} which={which}/>
//<Block title="Subscription" fetchPage={fetchPage} data={options} icon='wallet-q' setWhich={setWhich} which={which}/>
/*<Block title="Dashboard" fetchPage={fetchPage} data={dash} icon='grid-q' setWhich={setWhich} which={which}/>
                    {agentInfo.organization ==='master' && (
                        <Block title="Sync Leads" fetchPage={fetchPage} icon={faArrowProgress} data={sync} setWhich={setWhich} which={which}/>
                    )}
                    {agentInfo.organization !== 'master' && (
                        <Block title="Add Leads" fetchPage={fetchPage} data={leads} icon={faUserPlus} setWhich={setWhich} which={which}/>
                    )}
                    <Block title="To-Dos" fetchPage={fetchPage} data={todo} icon='q-check-done' setWhich={setWhich} which={which}/>
                    <Block title="Team" fetchPage={fetchPage} data={team} icon='q-dataflow-1' setWhich={setWhich} which={which}/>
                    <Block title="Subscription" fetchPage={fetchPage} data={options} icon={faMoneyCheckDollar} setWhich={setWhich} which={which}/>
                    <Block title="Activity" fetchPage={fetchPage} data={activity} icon={faWavePulse} setWhich={setWhich} which={which}/>
                    <Block title="Integrations" fetchPage={fetchPage} data={appSettings} icon={faGear} setWhich={setWhich} which={which}/>
                    <Block title="Phone" fetchPage={fetchPage} data={phone} icon='q-phone-call' setWhich={setWhich} which={which}/>
                    <Block title="Market" fetchPage={fetchPage} icon='q-shopping' data={market} setWhich={setWhich} which={which}/>*/